body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.IntroCard{
  background-color: rgb(36, 34, 34);
  border-radius: 20px;
  padding: 20px;
}

.txt{
  color: green; 
  font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
  font-size: 20px;
  letter-spacing: 0.15em;
}

.introImg img{
  width: 100%;
  border: 2px double lightgreen;
  border-radius: 20px;
}
.CTN{
 display:grid;
 grid-template-columns: auto auto;
 grid-template-rows: auto auto auto;
 grid-gap: 10px;
}
.introImg img:hover{
  box-shadow: 0 0 40px 0 green;
}
.aboutMe{
  grid-column-start:1;
  grid-column-end:1;
  grid-row-start:1;
  grid-row-end:1;
  background-color: rgba(255, 0, 0, 0.466);
  border-radius: 20px;
  padding:10px;
  color:white;
}
.aboutMe:hover{
  color:yellow;
}
.PSP{
grid-column-start:1;
grid-column-end:2;
grid-row-start:2;
grid-row-end:3;
background-color: bisque;
border-radius: 20px;
padding:10px;
}
.PSP:hover{
  color:darkblue;
}
.cnt{
  grid-column-start:2;
  grid-column-end:3;
  grid-row-start:1;
  grid-row-end:3;
  background-color: rgba(15, 92, 180, 0.466);
  border-radius: 20px;
  padding:10px;
  color:white;
}
.cnt:hover{
 color:darkorange;
}
.services{
  grid-column-start:1;
  grid-column-end:3;
  grid-row-start:3;
  grid-row-end:4;
  background-color: royalblue;
  border-radius: 20px;
  padding:10px;
  color:white;
}
.services:hover{
  color:antiquewhite;
}
.skills{
  background-color: whitesmoke;
  border-radius: 20px;
  padding:10px;
  margin-top: 10px;
}
.skill p{
  color:black;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 20px;
}
.pb{
  height:24px;
  background-color: darkgray;
}
.html-label{
  width: 90%;
}
.css-label{
  width: 90%;
}
.js-label{
  width: 80%;
}
.react-label{
  width: 60%;
}
.nodejs-label{
  width: 70%;
}
.skill:hover p{
  color:darkblue;
}
.skill:hover .pb{
  background-color: black;
}
.projects{
  display: grid;
  grid-gap: 10px;
}
.pjHeader{
  display:none;
}
.projectDetail{
  background-color: rgb(201, 196, 189);
  border-radius: 20px;
  padding: 20px;
}
.PJPageHeader{
  color: white;
  font-family: 'Times New Roman', Times, serif;
}
.contactPage{
  display:grid;
  padding: 10px;
}
.contactInfo{
  grid-column-start:1;
  grid-column-end:2;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
}
.contactForm{
  grid-column-start:2;
  grid-column-end:3;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:white;
  padding:10px;
}
.contactForm label{
  color:black;
}
.contactForm h2{
  font-family: 'Times New Roman', Times, serif;
  font-size: 60px;
}
table{
  border-collapse: collapse;
}
th,td{
  text-align: left;
  padding: 8px;
}
.contactPageHeader{
  color: white;
  font-family: 'Times New Roman', Times, serif;
}
@media screen and (max-width: 600px) {
  .projects{
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
  .pjHeader{
    display: block;
  }
  .contactPage{
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .contactForm h2{
    font-family: 'Times New Roman', Times, serif;
    font-size: 40px;
  }
  .CTN{
    grid-template-columns: auto ;
    grid-template-rows: auto auto auto auto;
    grid-gap: 10px;
   }
   .aboutMe{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
  }
  .PSP{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:2;
    grid-row-end:3;
    }
    .cnt{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:3;
      grid-row-end:4;
    }
    .services{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:4;
      grid-row-end:5;
    }
    .contactInfo{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:1;
      grid-row-end:2;
    }
    .contactForm{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:2;
      grid-row-end:3;
      border-top: 1px solid gold;
    }
}
@media screen and (max-width: 800px) {
  .projects{
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
  .contactForm h2{
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
  }
  .contactForm{
    border-top: 1px solid gold;
  }
  .pjHeader{
    display: block;
  }
  .contactPage{
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .CTN{
    grid-template-columns:  auto;
    grid-template-rows: auto auto auto auto;
    grid-gap: 10px;
   }
   .aboutMe{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:2;
  }
  .PSP{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:2;
    grid-row-end:3;
    }
    .cnt{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:3;
      grid-row-end:4;
    }
    .services{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:4;
      grid-row-end:5;
    }
    .contactInfo{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:1;
      grid-row-end:2;
    }
    .contactForm{
      grid-column-start:1;
      grid-column-end:2;
      grid-row-start:2;
      grid-row-end:3;
    }
}
@media screen and (min-width: 1000px) {
  .projects{
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
  }
  .contactPage{
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
}
